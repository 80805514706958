// ! header global styles 

.header {
  text-align: center;
  background-size: cover;
  padding: 0 20px;
}

.navbar {
  overflow: hidden;
  background-color: $c-white;
  z-index: 1;
  padding: 10px 0;

  @media(max-width: $bp-md) {
    padding: 0;
  }
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  transition: all 0.5s ease;
  animation: smoothScroll 1s forwards;

  -webkit-box-shadow: 0px 0px 10px -3px rgba(110,110,110,0.71);
  -moz-box-shadow: 0px 0px 10px -3px rgba(110,110,110,0.71);
  box-shadow: 0px 0px 10px -3px rgba(110,110,110,0.71);
}

@keyframes smoothScroll {
  0% {
    transform: translateY(-142px);
  }

  100% {
    transform: translateY(0px);
  }
}

.logoFull {
  // max-width: 400px;
  max-width: 600px;
  // padding: 20px 0;

  @media(max-width: $bp-sm) {
    // max-width: 250px;
    max-width: 480px;
    padding-bottom: 10px;
  }
}

.desc {
  font-size: 30px;
  line-height: 36px;
  max-width: 520px;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 15px;

  @media(max-width: $bp-md) {
    font-size: 25px;
  }

  @media(max-width: $bp-sm) {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 10px;
  }
}

.headerDesktop {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  @media(max-width: $bp-lg) {
    display: none;
  }
}

.logo-full{
  max-width: 130px;
}
.logoSmall {
  width: 20%;
  max-width: 150px;
  // margin: 0 auto 10px auto;
  padding: 5px 10px;

  @media(max-width: $bp-sm) {
    max-width: 140px;
    margin-bottom: 10px;
  }
}

.nav {
  margin: auto;
  width: 80%;
  @media(max-width: $bp-md) {
    display: none;
  }
}

.navList {
  display: flex;
  gap: 50px;
  align-items: center;
  // justify-content: center;
  justify-content: end;
  flex-wrap: wrap;
  font-size: $fs-small;

  @media(max-width: $bp-lg) {
    gap: 30px;
  }

  @media(max-width: 900px) {
    gap: 15px;
  }

  @media(max-width: $bp-md) {
    gap: 15px;
    justify-content: space-around;
  }

  @media(max-width: 576px) {
    gap: 10px;
    padding: 0 10px;
    font-size: 14px;
  }
}

.headerMobile {
  display: none;

  @media(max-width: $bp-lg) {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    align-items: center;
  }
}

//  ! here starts mobile version of the header

.navMobile {
  // 
}

.burgerLogo {
  max-width: 130px;
}

#burgerIcon {
  color: $c-black ;
}

.navListMobile {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  
  padding: 24px 10px;

  @media(max-width: $bp-md) {
    padding: 24px 10px;
  }
}

.navlinkMobile {
  line-height: 26px;
  font-size: $fs-base;

  @media(max-width: $bp-sm) {
    line-height: 24px;
    font-size: 18px;
  }
}