@import '../../../styles/variables';

.artSection {
  padding: 0 20px;
  max-width: 900px;
  text-align: center;
  // margin: 80px auto 1px auto; 
  margin: 20px auto 1px auto; 
  scroll-margin-top: 150px;

  @media(max-width: $bp-sm) {
    margin-top: 30px;
  }
}

.uptitle {
  font-weight:  $fw-bold;
  margin-bottom: 10px;
}

.subtitle {
  font-weight:  $fw-bold;
  margin-bottom: 40px;
}

.desc {
  line-height: 24px;
  margin-bottom: 40px;
}

.buttonsWrap {
  display: flex;
  justify-content: center;
  gap: 25px;

  @media(max-width: $bp-sm) {
    gap: 15px;
  }
}