.customButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 210px;
  height: 50px;
  cursor: pointer;
  outline: none;
  border: none;
  background-color: $c-black;
  color: $c-white;
  font-size: $fs-base;
  font-weight: $fw-bold;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    top: -1px;
    left: 0;
    background-color: $c-yellow;
    transform-origin: bottom right;
    transition: transform 0.3s ease-out;
  }

  &:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: -1px;
    left: 0;
    background-color: $c-red;
    transform-origin: top right;
    transition: transform 0.3s ease-out;
  }

  &:hover:before {
    transform: scaleX(1);
    transform-origin: top right;
  }

  @media(max-width: $bp-sm) {
    font-size: $fs-small;
  }
}