@import '../../../styles/variables';

.resultSection {
  text-align: center;
  padding: 130px 20px 40px 20px;
  background: linear-gradient(180deg, white 60%, #ededed91 85%);

  @media(max-width: $bp-md) {
    padding-top: 90px;
  }

  @media(max-width: $bp-sm) {
    padding-top: 60px;
  }
}

.numbersWrap {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 40px;
  margin-top: 40px;

  @media(max-width: $bp-md) {
    gap: 15px;
  }

  
  @media(max-width: $bp-md) {
    flex-direction: column;
  }
}

.numberWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.number {
  font-size: $fs-xl;
  font-weight: $fw-bold;
  line-height: 180px;
  color: #F2739E;

  @media(max-width: $bp-lg) {
    font-size: $fs-h1;
  }

  @media(max-width: $bp-md) {
    font-size: $fs-h2;
    line-height: 140px;
  }

  @media(max-width: $bp-sm) {
    font-size: 80px;
    line-height: 100px;
  }
}

.title {
  font-size: 30px;
}

.subtitle {

}