// This styles are linked in index.scss which is linked in app.jsx and working already.

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

img {
  width: 100%;
}

body {
  font-family: $ff-main;
  font-size: $fs-base;

  @media(max-width: $bp-sm) {
    font-size: $fs-small;
  }
  
}

img {
  pointer-events: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h2 {
  font-size: $fs-h2;
}

h3 {
  font-size: $fs-h3;
}

ul {
  list-style-type: none;
}

button {
  border: none;
  outline: none;
  background-color: transparent;
}

a {
  text-decoration: none;
  color: $c-black;
  position: relative;
  transition: text-shadow .2s;

  &:hover {
    text-shadow: 0 0 .65px #333, 0 0 .65px #333;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: -1px;
    left: 0;
    background-color: $c-yellow;
    transform-origin: bottom right;
    transition: transform 0.3s ease-out;
  }

  &:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
}

.active {
  text-shadow: 0 0 .65px #333, 0 0 .65px #333;
}

.title-h2 {
  font-size: $fs-h2;
  color: $c-black;
  font-weight: $fw-bold;
  margin-bottom: 18px;
  line-height: 120px;

  @media(max-width: $bp-md) {
    font-size: 75px;
    line-height: 100px;
  }

  @media(max-width: $bp-sm) {
    font-size: $fs-h3;
    line-height: 60px;
    margin-bottom: 16px;
  }
}

.title-h3 {
  font-size: $fs-h3;
  color: $c-black;
  font-weight: $fw-bold;
  margin-bottom: 18px;
  line-height: 60px;

  @media(max-width: $bp-md) {
    font-size: 40px;
  }

  @media(max-width: $bp-sm) {
    font-size: 30px;
    margin-bottom: 10px;
    line-height: 45px;
  }
}

// #connectEvolve .textContentWrap{
//   max-width: 550px;
// }
#how-it-works{
  text-align: center;
  padding: 100px 0px 0px;

  @media(max-width: $bp-md) {
    padding: 60px 0px 0px;
  }
}
#wrappedNFT{
  padding: 30px 20px 0 20px;
}

.h_video_Section{
  height: 600px;
  width: 100%;

  @media(max-width: $bp-md) {
    height: 400px;
  }
  iframe{
    width: 100%;
  }
}
.App{
  margin-top: 80px;
  @media(max-width: $bp-md) {
    margin-top: 70px;
  }
}