@import '../../../styles/variables';

.hiddenName {
  background: $c-grey;
  display: inline-block;
  width: 125px;
  color: transparent;

  @media(max-width: $bp-sm) {
    width: 85px;
  }
}