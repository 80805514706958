@import '../../../styles/variables';

.footer {
  font-size: 30px;
  text-align: center;
  padding: 50px 20px;

  @media(max-width: $bp-md) {
    font-size: 25px;
    padding: 30px 10px;
  }

  @media(max-width: $bp-sm) {
    font-size: 20px;
    padding: 20px 10px;
  }
}