#connectEvolve {
  justify-content: space-between;
  position: relative;
}

#frameWrap {
  // border: 3px salmon solid;
  height: 430px;

  @media(max-width: $bp-sm) {
    height: 400px;
  }

  @media(max-width: $bp-xs) {
    height: 340px;
  }
}

#pluscard {
  width: 700px;
  height: 700px;

  -ms-zoom: 0.65;
  -moz-transform: scale(0.65);
  -webkit-transform: scale(0.65);
  -o-transform: scale(0.65);
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;

  position: absolute;
  left: 55%;

  @media(max-width: $bp-lg) {
    left: 30vw;
  }

  @media(max-width: 980px) {
    left: 28vw;
  }

  @media(max-width: 860px) {
    left: 24vw;
  }

  @media(max-width: $bp-md) {
    left: 18vw;
  }

  @media(max-width: 650px) {
    left: 14vw;
  }

  @media(max-width: $bp-sm) {
    left: 20%;
    width: 500px;
    height: 600px;
  }

  @media(max-width: $bp-xs) {
    -ms-zoom: 0.55;
    -moz-transform: scale(0.55);
    -webkit-transform: scale(0.55);
    // left: 20%;
  }

  @media(max-width: 400px) {
    left: 15%;
  }

  @media(max-width: 350px) {
    left: 9%;
  }
}



