@import '../../../styles/variables';

.leaderboard {
  padding: 130px 20px 0 20px;
  text-align: center;
  margin: 0 auto 1px auto;

  @media(max-width: $bp-md) {
    padding-top: 90px;
  }

  @media(max-width: $bp-sm) {
    padding-top: 60px;
  }
}

.desc {
  line-height: 24px;
  text-align: center;
  max-width: 730px;
  margin: 20px auto 40px auto;
}

.imgWrap {
  background: $c-black;
  margin-right: -20px;
  margin-left: -20px;
  padding-bottom: 60px;
  display: block;

  &:after {
    content: none;
  }
}

.img {
  max-width: 770px;
  padding: 0 10px;
}