@import '../../../styles/variables';

.userStorySection {
  padding-top: 60px;
  padding-bottom: 30px;
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1500px;
  margin: 0 auto;

  @media(max-width: $bp-sm) {
    padding-top: 40px;
    padding-bottom: 10px;
  }
}

.contentWrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;

  @media(max-width: $bp-md) {
    gap: 15px;
  }
}

.descTop {
  font-size: $fs-small;
  font-style: italic;
  max-width: 45%;
  align-self: flex-start;

  span {
    font-weight: $fw-bold;
  }

  @media(max-width: $bp-md) {
    max-width: 100%;
  }
}

.descMid {
  font-size: $fs-small;
  font-style: italic;
  max-width: 45%;
  align-self: center;
  margin: 32px 0px;

  span {
    font-weight: $fw-bold;
  }

  @media(max-width: $bp-md) {
    max-width: 100%;
  }
}

.image {
  max-width: 560px;
}

.descBottom {
  font-size: $fs-small;
  font-style: italic;
  max-width: 45%;
  align-self: flex-end;

  span {
    font-weight: $fw-bold;
  }

  @media(max-width: $bp-md) {
    max-width: 100%;
    margin-bottom: 10px;
  }
}

.subtitleWrap {
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight:  $fw-bold;
  align-self: flex-start;
  margin-left: 15vw;

  @media(max-width: $bp-md) {
    margin-left: 0;
  }
  
}

.subimg {
  width: 53px;
  height: 53px;
  background: $c-grey;
  border-radius: 50%;
  object-fit: cover;

  @media(max-width: $bp-sm) {
    width: 47px;
    height: 47px;
  }
}

