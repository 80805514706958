// @import 'styles/variables.scss';

.MuiAppBar-root {
  background-color: #e7e7e7 !important;
  padding: 0 !important;
  
}

.MuiToolbar-root {
  justify-content: flex-end;
  min-height: 50px !important;
}