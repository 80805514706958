@import '../../../styles/variables';

.gallery {
  padding: 0 20px;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 1px;
  background: linear-gradient(180deg, white 60%, #ededed91 85%);
  padding-top: 130px;

  @media(max-width: $bp-md) {
    padding-top: 90px;
  }

  @media(max-width: $bp-sm) {
    padding-top: 60px;
  }
}

.desc {
  line-height: 24px;
  text-align: center;
  max-width: 730px;
  margin: 0 auto 40px auto;
}

.imgWrap {
  display: block;
  
  &:after {
    content: none;
  }
  
}

.img {
  max-width: 1020px;
  padding: 0 20px;
}