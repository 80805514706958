// colors
$c-black: #000000;
$c-white: #ffffff;
$c-grey: #D9D9D9;
$c-grey-light: #F5F5F5;

$c-red: #e25241; 
$c-yellow: #f7ca50; 

// Fonts variables
$ff-main: 'Urbanist', sans-serif;

// font sizes
$fs-xl: 150px;
$fs-h1: 120px;
$fs-h2: 100px;
$fs-h3: 50px;
$fs-base: 20px;
$fs-small: 16px;

$bp-xs: 480px;
$bp-sm: 576px;
$bp-md: 768px;
$bp-lg: 1200px;
$bp-xl: 1400px;


// font weignt
$fw-semibold: 500;
$fw-bold: 700;
