@import '../../../styles/variables';

.rightImg {
  display: flex;
  gap: 50px;
  justify-content: center;
  padding: 130px 20px 0 20px;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 1px;

  @media(max-width: $bp-lg) {
    flex-direction: column;
  }

  @media(max-width: $bp-md) {
    padding-top: 90px;
  }

  @media(max-width: $bp-sm) {
    padding-top: 60px;
  }
}

.leftImg {
  display: flex;
  flex-direction: row-reverse;
  gap: 50px;
  justify-content: center;
  padding: 130px 20px 0 20px;
  margin-bottom: 1px;

  @media(max-width: $bp-lg) {
    flex-direction: column;
  }

  @media(max-width: $bp-md) {
    padding-top: 90px;
  }

  @media(max-width: $bp-sm) {
    padding-top: 60px;
  }
}

.textContentWrap {
  // max-width: 420px;
  max-width: 550px;
  margin: auto;

  @media(max-width: $bp-lg) {
    max-width: 100%;
    text-align: center;
  }
}

.uptitle {
  font-weight:  $fw-bold;
}

.desc {
  line-height: 24px;
  margin-bottom: 30px;
}

.subtitleWrap {
  // display: flex;
  display: none;
  align-items: center;
  gap: 12px;
  font-weight:  $fw-bold;
}

.subimg {
  width: 53px;
  height: 53px;
  background: $c-grey;
  border-radius: 50%;
  object-fit: cover;

  @media(max-width: $bp-sm) {
    width: 47px;
    height: 47px;
  }
}

.img{
  
  max-width: 600px;
  margin: auto;
  display: flex;
  
}
